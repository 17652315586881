/* eslint-disable react/forbid-prop-types */

import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function FormAutocompleteInput({
  control,
  name,
  label,
  defaultValue,
  required = false,
  disabled = false,
  options,
  rules,
  id,
  freeSolo = false,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          freeSolo={freeSolo}
          options={options}
          getOptionLabel={(option) => option.name}
          onInputChange={(event, value) => {
            if (freeSolo) {
              field.onChange(value);
            }
          }}
          onChange={(event, value) => field.onChange(value ? value.id : null)}
          value={options.find((option) => option.id === field.value) || null}
          isOptionEqualToValue={(option, value) => option.id === value}
          filterSelectedOptions
          id={id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="standard"
              required={required}
              data-testid={id}
              disabled={disabled}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
      )}
      rules={rules}
    />
  );
}

FormAutocompleteInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object.isRequired,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string.isRequired,

  /**
   * The id of your input field
   */
  id: PropTypes.string,

  /**
   * **Important:** cannot apply undefined to `defaultValue`
   * * You need to either set `defaultValue` at the field-level
   *   or `useForm`'s `defaultValue`s.  Undefined is not a valid value.
   * * If your form will invoke `reset` with default values,
   *   you will need to provide `useForm` with `defaultValues`
   */
  defaultValue: PropTypes.string,

  /**
   * If `true`, the label is displayed as required
   * and the input element is required.
   */
  required: PropTypes.bool,

  /**
   * If `true` the component is disabled
   */
  disabled: PropTypes.bool,

  /**
   * Each item must be an object that contains
   * `id` and `name` properties
   */
  options: PropTypes.array,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */
  rules: PropTypes.object,

  /**
   * Allows for inputs that are not included in the options list
   */
  freeSolo: PropTypes.bool,
};

export default FormAutocompleteInput;
