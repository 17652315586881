import React from 'react';

import DarkModeIcon from '@mui/icons-material/DarkModeOutlined';
import SystemModeIcon from '@mui/icons-material/SettingsBrightness';
import LightModeIcon from '@mui/icons-material/WbSunny';

import { useTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

const THEMES = [
  { name: 'light', icon: <LightModeIcon /> },
  { name: 'system', icon: <SystemModeIcon /> },
  { name: 'dark', icon: <DarkModeIcon /> },
];

function ThemePicker() {
  const theme = useTheme();
  const { selectedTheme, handleSelectTheme } = theme;

  return (
    <>
      <Typography variant="body2" pl={1}>
        Theme
      </Typography>
      <ToggleButtonGroup
        color="secondary"
        value={selectedTheme}
        exclusive
        size="small"
        onChange={handleSelectTheme}
        fullWidth
      >
        {THEMES.map(({ name, icon }) => (
          <ToggleButton
            data-cy={`theme-button-${name}`}
            selected={name === selectedTheme}
            value={name}
            key={name}
            sx={{ textTransform: 'capitalize' }}
          >
            {icon}
            <Typography variant="body2" pl={1}>
              {name}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
}

export default ThemePicker;
